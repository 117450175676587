import { NavLink } from '@remix-run/react';
import type { ReactNode } from 'react';

import { Text, Loader, ThemeIcon } from '@mantine/core';
import {
  IconSettings,
  IconEdit,
  IconHistory,
  IconMap,
  IconDeviceAnalytics,
  IconChartBar,
  IconListDetails } from
'@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import classes from './NavbarMenus.module.css';

export const NAV_TABS = ({
  LIVE_MAP: 'live',
  HISTORY: 'history',
  DRAFTER: 'drafter',
  SETTINGS: 'settings',
  ANALYSES: 'analyses',
  AGV_TASKS: 'agv-tasks',
  AGV_STATS: 'agv-statistics'
} as const);

interface NavbarLinkProps {
  label: string;
  icon: ReactNode;
  to: string;
}

const NavbarLink = (props: NavbarLinkProps) => {
  const { icon, label, to } = props;
  return (
    <NavLink to={to} className={classes.link}>
      {({ isActive, isPending }) =>
      <>
          {isPending ?
        <Loader className={classes.loader} data-active={isActive} /> :

        <ThemeIcon className={classes.icon} data-active={isActive}>
              {icon}
            </ThemeIcon>}

          <Text className={classes.label} data-active={isActive}>
            {label}
          </Text>
        </>}

    </NavLink>);

};

export const NavbarMenus = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavbarLink to={`/${NAV_TABS.LIVE_MAP}`} label={t(`navbar.${NAV_TABS.LIVE_MAP}`)} icon={<IconMap />} />
      <NavbarLink to={`/${NAV_TABS.HISTORY}`} label={t(`navbar.${NAV_TABS.HISTORY}`)} icon={<IconHistory />} />
      <NavbarLink to={`/${NAV_TABS.DRAFTER}`} label={t(`navbar.${NAV_TABS.DRAFTER}`)} icon={<IconEdit />} />
      <NavbarLink to={`/${NAV_TABS.SETTINGS}`} label={t(`navbar.${NAV_TABS.SETTINGS}`)} icon={<IconSettings />} />
      {globalThis.ENV.ENABLE_ANALYSIS === 'true' &&
      <NavbarLink to={`/${NAV_TABS.ANALYSES}`} label={t(`navbar.${NAV_TABS.ANALYSES}`)} icon={<IconDeviceAnalytics />} />}

      {globalThis.ENV.ENABLE_AGV_TABS === 'true' &&
      <>
          <NavbarLink to={`/${NAV_TABS.AGV_TASKS}`} label={t(`navbar.${NAV_TABS.AGV_TASKS}`)} icon={<IconListDetails />} />
          <NavbarLink to={`/${NAV_TABS.AGV_STATS}`} label={t(`navbar.${NAV_TABS.AGV_STATS}`)} icon={<IconChartBar />} />
        </>}

    </>);

};